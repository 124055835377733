import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Portal,
  SvgIcon,
  Typography,
  Button,
  LinearProgress,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  X as XIcon,
  ChevronDown,
  ChevronUp,
  Save as SaveIcon,
} from 'react-feather';
import { closeChecklist } from 'src/actions/checklistActions';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { CustomField } from 'src/components';
import moment from 'moment';
import {
  useAddChecklistMutation,
  useGetChecklistQuery,
  useUpdateChecklistMutation,
} from 'src/store/serverState';
import { Checklist, ChecklistValues } from 'types';
import { useAppSelector } from 'src/store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)})`,
    maxHeight: `calc(100% - ${theme.spacing(6)})`,
    width: 800,
    height: 'auto',
    position: 'fixed',
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    outline: 'none',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    flexDirection: 'column',
  },
  bottomSide: {
    bottom: 0,
    right: 0,
  },
  topSide: {
    top: 95,
    right: 0,
  },
  fullScreen: {
    height: '80%',
    width: '40%',
    minWidth: 650,
  },
  input: {
    width: '100%',
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      minHeight: 300,
    },
    overflow: 'hidden',
  },
  action: {
    marginRight: theme.spacing(1),
  },
  actionRight: {
    align: 'right',
    marginLeft: theme.spacing(0),
  },
  hidden: {
    display: 'none',
  },
  formControlLabel: {
    fontSize: '0.9rem',
  },
  selectbox: {
    marginLeft: 10,
    marginTop: 10,
    width: 100,
  },
  checkboxesBox: {
    overflowY: 'auto',
  },
  save: {
    color: '#28B3E5',
    '&:hover': {
      color: '#84d3f0', // 20% lighter on hover
      backgroundColor: '#f9fafb', // 2% lighter on hover
    },
  },
  iconButton: {
    padding: 0,
  },
  label: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'normal',
    marginLeft: 10,
    marginTop: 20,
    flex: 1,
  },
  checklistItemBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
}));

function CaseChecklist({ caseId }: { caseId: string }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addCheckList] = useAddChecklistMutation();
  const [updateCheckList] = useUpdateChecklistMutation();
  const checkList = useGetChecklistQuery(caseId);
  const checklistOpen = useAppSelector(
    (state) => state.checklist.checklistOpen,
  );
  const [fullScreen] = useState(false);
  const [flipped, setFlipped] = useState(false);

  const [currentChecklist, setCurrentChecklist] = useState<Checklist[] | []>(
    [],
  );
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (newValue: ChecklistValues, idx: number) => {
    setModified(true);
    const res = currentChecklist.map((item) =>
      item.id === idx
        ? {
            ...item,
            modified: true,
            caseChecklists: { ...item.caseChecklists, value: newValue },
          }
        : item,
    );
    setCurrentChecklist(res);
  };

  const handleSave = async () => {
    try {
      const newList = [];
      const updateList = [];
      for (const list of currentChecklist) {
        if (list?.caseChecklists?.id) {
          updateList.push(list.caseChecklists);
        } else {
          newList.push({
            ...list.caseChecklists,
            caseId,
            checklistItemId: list.id,
          });
        }
      }

      if (newList.length > 0) {
        await addCheckList({ caseId, data: newList }).unwrap();
      }
      if (updateList.length > 0) {
        await updateCheckList({ caseId, data: updateList }).unwrap();
      }

      setModified(false);
      enqueueSnackbar('Checklist saved!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (err) {
      enqueueSnackbar('Unable to save checklist', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const handleClose = (discard: boolean) => {
    if (modified && !discard) {
      enqueueSnackbar('You have unsaved changes!', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        action: (
          <Button
            color="primary"
            size="small"
            onClick={() => handleClose(true)}
          >
            Discard and close
          </Button>
        ),
      });
    } else {
      setCurrentChecklist([]);
      dispatch(closeChecklist());
    }
  };

  const handleSwitchSides = () => {
    setFlipped(!flipped);
  };

  useEffect(() => {
    if (checklistOpen && !checkList.isFetching) {
      setLoading(true);
      setCurrentChecklist(checkList.data || []);
      setModified(false);
      setLoading(false);
    }
  }, [checklistOpen, checkList.isFetching]);

  if (!checklistOpen) {
    return null;
  }

  return (
    <Portal>
      <Paper
        className={clsx(classes.root, {
          [classes.fullScreen]: fullScreen,
          [classes.topSide]: flipped,
          [classes.bottomSide]: !flipped,
        })}
        elevation={12}
      >
        <Box
          bgcolor="background.dark"
          display="flex"
          alignItems="center"
          py={1}
          px={2}
        >
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Case Checklist
          </Typography>
          <Box flexGrow={1} />
          <IconButton
            onClick={handleSave}
            className={classes.iconButton}
            size="large"
          >
            <SvgIcon
              fontSize="small"
              className={classes.save}
            >
              <SaveIcon />
            </SvgIcon>
          </IconButton>
          {flipped ? (
            <IconButton
              onClick={handleSwitchSides}
              size="large"
            >
              <SvgIcon fontSize="small">
                <ChevronDown />
              </SvgIcon>
            </IconButton>
          ) : (
            <IconButton
              onClick={handleSwitchSides}
              size="large"
            >
              <SvgIcon fontSize="small">
                <ChevronUp />
              </SvgIcon>
            </IconButton>
          )}
          <IconButton
            onClick={() => handleClose(false)}
            size="large"
          >
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Divider />
        {loading || checkList.isLoading ? (
          <LinearProgress />
        ) : (
          <Box className={classes.checkboxesBox}>
            {!!currentChecklist?.length &&
              currentChecklist.map((checkItem, i) => (
                <Box
                  key={checkItem.id}
                  className={classes.checklistItemBox}
                >
                  <Box className={classes.selectbox}>
                    <CustomField
                      inputType="select"
                      noColorChange
                      writeOnly
                      options={[
                        { label: 'N/A', value: 'n/a' },
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                      ]}
                      optionLabel="label"
                      optionValue="value"
                      value={checkItem?.caseChecklists?.value || null}
                      cbChange={({ value }: { value: ChecklistValues }) =>
                        handleChange(value, checkItem.id)
                      }
                    />
                  </Box>
                  <Box style={{ flex: 2 }}>
                    <Typography className={classes.label}>
                      {checkItem?.name}
                    </Typography>
                  </Box>
                  {checkItem?.caseChecklists?.value ? (
                    <>
                      <Box className={classes.label}>
                        <CustomField
                          inputType="text"
                          readOnly
                          viewOnly
                          noColorChange
                          value={
                            // eslint-disable-next-line no-nested-ternary
                            checkItem.modified === true
                              ? ''
                              : checkItem?.caseChecklists
                                  ?.caseChecklistToUserUpdatedBy
                              ? `${checkItem?.caseChecklists?.caseChecklistToUserUpdatedBy?.firstName} ${checkItem?.caseChecklists?.caseChecklistToUserUpdatedBy?.lastName}`
                              : checkItem?.caseChecklists
                                  ?.caseChecklistToUserCreatedBy
                              ? `${checkItem?.caseChecklists?.caseChecklistToUserCreatedBy?.firstName} ${checkItem?.caseChecklists?.caseChecklistToUserCreatedBy?.lastName}`
                              : ''
                          }
                        />
                      </Box>
                      <Box className={classes.label}>
                        <CustomField
                          inputType="text"
                          readOnly
                          viewOnly
                          noColorChange
                          value={moment(
                            checkItem?.caseChecklists?.updatedDate ||
                              new Date(),
                          ).format('MM/DD/YYYY HH:mm')}
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              ))}
          </Box>
        )}
      </Paper>
    </Portal>
  );
}

export default CaseChecklist;
