import React, { useState, useEffect, useCallback, memo } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  Autocomplete,
  TextField,
  Chip,
  Tooltip,
  useTheme,
  AutocompleteProps,
  AutocompleteValue,
  TextFieldProps,
} from '@mui/material';
import moment from 'moment-timezone';
import userService from 'src/services/userService';
import roleService from 'src/services/roleService';
import permissionService from 'src/services/permissionService';

import groupService from 'src/services/groupService';
import partnerService from 'src/services/partnerService';

import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useGetOutcomesQuery,
  useGetOrganizationsByMultipleParamsQuery,
  useGetUsersByRoleQuery,
  useGetLookupQuery,
  useGetPartnersQuery,
  useGetOutcomesDetailsQuery,
} from 'src/store/serverState';
import { useAppSelector } from 'src/store';

const DEFAULT_FILTER = () => true;
const DEFAULT_FUNC = () => {};
const DEFAULT_OPTIONS: any[] = [];
type D = boolean | undefined;
export interface FormSelectProps<
  Options,
  Multiple extends D = undefined,
  DisableClearable extends D = undefined,
  FreeSolo extends D = undefined,
> extends Omit<
    AutocompleteProps<Options, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'options'
  > {
  options?: readonly Options[];
  label?: string;
  required?: boolean;
  writeOnly?: boolean;
  textfieldProps?: TextFieldProps;
  error?: string;
  viewOnly?: boolean;
  removeIcons?: boolean;
  fixedOptions?: any[];
  zeroPadding?: boolean;
  dateValue?: string;
  partners?: boolean;
  noPadding?: boolean;
  orgPartners?: boolean;
  orgPartnersType?: boolean;
  newLoader?: boolean;
  table?: boolean;
  secured?: boolean;
  filter?: ((f: unknown) => boolean) | undefined;
  lookupWhere?: boolean;
  noOptionsDisabled?: boolean;
  roles?: string;
  organizations?: string;
  order?: string;
  squared?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  helperText?: string;
  name?: string;
  noColorChange?: boolean;
  width?: string;
  maxWidth?: string;
  customStyle?: React.CSSProperties;
  noFlash?: boolean;
  outcomes?: boolean;
  outcomeDetails?: { type: string; outcome: string };
  changeCallback?: ({ value, raw }: any) => void;
  getPreviousValues?: (value: any, raw: any) => void;
  mapValues?: ({ value, raw }: any) => void;
  lookup?: string;
  mode?: string;
  disabledOptionValues?: any[];
  optionLabel?: keyof Options;
  optionValue?: keyof Options;
}
type TempInputStyling = {
  borderRadius?: number;
  padding?: string;
  paddingLeft?: string;
  fontFamily?: string;
};
type TempClasses = {
  notchedOutline?: string;
  underline?: string;
  input?: string;
};

function FormSelect<
  Options,
  Multiple extends D = undefined,
  DisableClearable extends D = undefined,
  FreeSolo extends D = undefined,
>({
  options: optionsProp = DEFAULT_OPTIONS, // an array of options
  disabledOptionValues, // the values of any options that should be disabled/non-selectable
  optionLabel: optionLabelProp, // string to point to the attribute for a string label
  optionValue: optionValueProp, // string to point to the attribute value to store and find option
  value: valueProp, // the inital value you want it to start with
  changeCallback = DEFAULT_FUNC, // function executed on change
  getPreviousValues = DEFAULT_FUNC, // returns the previous values
  mapValues = DEFAULT_FUNC, // function executed to map a value from select
  lookup, // lookup table name
  mode, // | yeno | timezone
  label, // the label of the *input*
  noFlash = false,
  outcomes: outcomesProp,
  outcomeDetails: outcomeDetailsProp,
  groupBy,
  width = '100%', // percentage
  maxWidth = '100%', // percentage
  customStyle,
  error: errorProp, // a string with the error message
  helperText: helperTextProp, // a string with any helper text information
  name: nameProp, // the name of the input
  noColorChange = false, // boolean if u want it to change color if no longer inital value
  variant: variantProp = 'outlined', // string of which variant it is (standard, filled, outlined)
  readOnly: readOnlyProp = false, // boolean for displaying normal but not allowing type (disabled is different)
  squared = false, // boolean if you want the border to be straight or roudned
  required: requiredProp = false, // boolean for checking required validation
  secured: securedProp = false, // boolean for checking if a value is suppose to be hidden
  filter: filterProp = DEFAULT_FILTER, // function for filtering
  disabled: disabledProp = false, // boolean for checking disabled,
  table: tableProp = false, // show or hide the border
  lookupWhere, // used to match a lookup to certain attributes (attribute:value)
  noOptionsDisabled = false, // boolean if you want the input to disable if no options are available
  roles: rolesProp, // the role to get the users by
  organizations: organizationsProp, // get organizations by type
  order: orderProp, // get order operator and selector
  writeOnly = false, // always be in write mode even if it in in view mode
  viewOnly = false, // always be in view mode even if it in in write mode
  placeholder: placeholderProp,
  size: sizeProp = 'small', // the size
  multiple: multipleProp, // can they select multiple entries
  removeIcons = false, // remove the dropdown and x icon
  fixedOptions: fixedOptionsProp = DEFAULT_OPTIONS,
  zeroPadding,
  dateValue,
  partners: partnersProp,
  noPadding = false,
  orgPartners: orgPartnersProp,
  orgPartnersType: orgPartnersTypeProp,
  newLoader,
  onChange = DEFAULT_FUNC,
  ...props
}: FormSelectProps<Options, Multiple, DisableClearable, FreeSolo>) {
  // move props to state'
  const outcomes = useGetOutcomesQuery(outcomesProp, { skip: !outcomesProp });
  const outcomesDetails = useGetOutcomesDetailsQuery(outcomeDetailsProp, {
    skip: !outcomeDetailsProp?.type || !outcomeDetailsProp?.outcome,
  });
  const organizations = useGetOrganizationsByMultipleParamsQuery(
    organizationsProp,
    {
      skip: !organizationsProp,
    },
  );
  const usersByRole = useGetUsersByRoleQuery(rolesProp, {
    skip: !rolesProp,
  });
  const lookupOptions = useGetLookupQuery(
    {
      modelName: lookup,
      attributes: null,
      order: 'nameAsc',
      where: lookupWhere,
    },
    { skip: !lookup },
  );
  const partnerOptions = useGetPartnersQuery(null, {
    skip: !partnersProp,
  });

  const [options, setOptions] = useState<Options[]>([]);
  const [optionLabel, setOptionLabel] = useState<keyof Options | undefined>(
    optionLabelProp,
  );
  const [optionValue, setOptionValue] =
    useState<typeof optionValueProp>(optionValueProp);
  const [fixedOptions, setFixedOptions] = useState(fixedOptionsProp);
  const [disabled, setDisabled] = useState(disabledProp);

  // set component state
  const [selectedValue, setSelectedValue] = useState<typeof valueProp | null>(
    null,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isFocused, setisFocused] = useState(false);
  const [customClasses, setCustomClasses] = useState({});
  const [inputStyling, setInputStyling] = useState({});
  const [isViewMode, setIsViewMode] = useState(true); // boolean for conditionally determining if the form is in view mode
  const [isInitialValue, setIsInitialValue] = useState(true); // boolean for conditionally determining if the selected value has changed from it's initial value
  const [initialValueField] = useState(valueProp);
  // use redux global state
  const formMode = useAppSelector((state) => state.formPermissions.mode);

  // mobile tooltip state and media query
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xl'));

  // a function to set the state of isViewMode and isInitialValue
  const setFormPermissionValues = useCallback(async () => {
    if (formMode) {
      if (writeOnly) {
        setIsViewMode(false);
      } else {
        setIsViewMode(viewOnly || formMode === 'view');
      }
    }
    if (selectedValue != null && initialValueField != null) {
      setIsInitialValue(
        String(selectedValue) === String(initialValueField) ||
          // @ts-ignore
          String(selectedValue[optionValue]) === String(initialValueField),
      );
    } else if (selectedValue != null) {
      setIsInitialValue(false);
    } else {
      setIsInitialValue(true);
    }
  }, [
    formMode,
    selectedValue,
    optionValue,
    initialValueField,
    writeOnly,
    viewOnly,
  ]);

  useEffect(() => {
    setFormPermissionValues();
  }, [setFormPermissionValues]);

  // set certain styling based on options
  const setStyling = useCallback(() => {
    // set initial temp variable for styling and classes to nothing
    const tempInputStyling: TempInputStyling = {};
    const tempClasses: TempClasses = {};

    // remove the rounded borders if squared is specified
    if (squared) {
      tempInputStyling.borderRadius = 0;
    }

    if (noPadding) {
      tempInputStyling.padding = '0px';
    }

    if (zeroPadding) {
      tempInputStyling.padding = '0px';
      tempInputStyling.paddingLeft = '0px';
    }

    // if secured is true then check if they are focused on the
    // field, if they are then set the font family to a bullet point
    // font family
    if (securedProp) {
      if (!isFocused) {
        tempInputStyling.fontFamily = 'text-security-disc';
      }
    }

    // set state to the temp variable and return
    setInputStyling(tempInputStyling);
    setCustomClasses(tempClasses);
    return { tempInputStyling, tempClasses };
  }, [
    securedProp,
    squared,
    noPadding,
    isFocused,
    readOnlyProp,
    zeroPadding,
    variantProp,
  ]);

  const checkIfDisabled = useCallback(() => {
    // check to see if disabled prop has been manually set
    if (disabledProp === true) {
      setDisabled(true);
      return true;
    }

    // check to see if disable if no options prop has been set
    // also check if there are options
    if (noOptionsDisabled && Boolean(!options?.length)) {
      setDisabled(true);
      return true;
    }

    // if no disabled checks are true, then set to false
    setDisabled(false);
    return false;
  }, [disabledProp, noOptionsDisabled, options]);
  const checkOptionSelected = (option: Options, state: Options) => {
    // make sure that options exist
    if (!option || !state) {
      return false;
    }

    // check if they have an attribute to compare
    if (optionValue) {
      // @ts-ignore
      return Boolean(option[optionValue] === state[optionValue]);
    }

    // if they don't have an option value thene just compare
    return Boolean(option === state);
  };

  // a function to render the text for the options in the list
  const showOptionLabel = (option: string | Options | typeof selectedValue) => {
    // if there is no option to show just show an empty string
    if (!option) {
      return '';
    }

    // if they have an option label attribute show that value in
    // the object as the label
    if (optionLabel && typeof option === 'object') {
      // @ts-ignore
      return option[optionLabel] as string;
    }

    // if there is no attribute specifying the label then
    // just show the option as the label
    return option as string;
  };

  // will return an object with 2 variables when onChange is called
  // value is the shorthand value being stored such as ID
  // raw is the whole object that it matches
  const handleChange = (
    val: AutocompleteValue<Options, Multiple, DisableClearable, FreeSolo>,
  ) => {
    // if it is secured, hide the value
    const prevValues = cloneDeep(selectedValue);
    if (securedProp) {
      setisFocused(true);
    }

    if (fixedOptions && fixedOptions.length) {
      if (
        // @ts-ignore
        selectedValue.filter((opt) => opt.sso === true).length !==
        // @ts-ignore
        val.filter((opt) => opt.sso === true).length
      ) {
        return null;
      }
    }
    // check if value is empty
    if (val === null || val === undefined || val === '') {
      setSelectedValue(null);
      mapValues({ value: null, raw: null });
      changeCallback({ value: null, raw: null });
      getPreviousValues(prevValues, val);
      return { value: null, raw: null };
    }
    // check if they have an attribute to make the value
    if (optionValue) {
      let regularValue = val;
      if (multipleProp) {
        // @ts-ignore
        regularValue = val.map((valueArray) => valueArray[optionValue]);
      } else {
        // @ts-ignore
        regularValue = val[optionValue];
      }
      setSelectedValue(val);
      mapValues({ value: regularValue, raw: val });
      changeCallback({ value: regularValue, raw: val });
      getPreviousValues(prevValues, val);
      return { value: regularValue, raw: val };
    }

    // if no attribute has been specified set it to whole value
    setSelectedValue(val);
    mapValues({ value: val, raw: val });
    changeCallback({ value: val, raw: val });
    getPreviousValues(prevValues, val);
    return { value: val, raw: val };
  };

  // a function ran initally to set the correct options
  // based on what props were sent
  // props can be lookup (taking in value of lookup table name)
  // or mode (taking in value (bool)
  const getPopulatedOptions = useCallback(async () => {
    const removeInactive = (allOptions: any[] = []) => {
      return allOptions.filter((opt) => {
        return (
          filterProp(opt) &&
          (String(opt) === String(initialValueField) ||
            String(opt[optionValue]) === String(initialValueField) ||
            (disabledOptionValues &&
              disabledOptionValues.includes(opt[optionValue])) ||
            ((opt.active != null ? opt.active : true) &&
              !(opt.suspended != null && opt.suspended)))
        );
      });
    };
    // set initial populated options to props
    // @ts-ignore
    let populatedOptions: Options[] = [...optionsProp];
    const optionValueId = 'id' as typeof optionValue;
    const optionValueGeneric = 'value' as typeof optionValue;
    const optionLabelName = 'name' as typeof optionLabel;
    const optionLabelFullName = 'fullName' as typeof optionLabel;
    const optionLabelGeneric = 'label' as typeof optionLabel;

    // throw error if both mode and lookup props were sent
    if (lookup && mode) {
      throw new Error("Can not send 'lookup' and 'mode' in the same select.");
    }

    // check if lookup table is specified
    if (lookup) {
      // TODO: throw error if lookup table does not exist
      // returns the data from the lookup table

      // check to see if they are looking certain items in the lookup
      if (lookupWhere) {
        const populatedOptions = removeInactive(lookupOptions.data);
        setOptions(populatedOptions);
        setOptionValue(optionValueId);
        setOptionLabel(optionLabelName);
        return populatedOptions;
      }
      const populatedOptions = removeInactive(lookupOptions.data);

      setOptions(populatedOptions);
      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelName);
      return populatedOptions;
    }

    // check to see if certain a certain organization was specified
    if (organizationsProp) {
      const populatedOptions = [
        ...removeInactive(organizations.data),
        ...optionsProp,
      ];

      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelName);
      setOptions(populatedOptions);
      return populatedOptions;
    }

    // check to see if certain role(s) were specified  (comma seperated)
    if (rolesProp) {
      // pull users by rule
      const populatedOptions = removeInactive(usersByRole.data);
      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelFullName);
      setOptions(populatedOptions);
      return populatedOptions;
    }

    // check to see if a certain partner was selected
    if (partnersProp) {
      // pull users by rule
      const populatedOptions = removeInactive(partnerOptions.data);
      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelName);
      setOptions(populatedOptions);
      return populatedOptions;
    }

    // check to see if a certain partner was selected
    if (outcomesProp) {
      // pull outcomes by type
      const populatedOptions = removeInactive(outcomes.data);
      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelName);
      setOptions(populatedOptions);
      return populatedOptions;
    }

    // check to see if a certain partner was selected
    if (outcomeDetailsProp?.outcome && outcomeDetailsProp?.type) {
      // pull outcome details by outcome and type
      const populatedOptions = removeInactive(outcomesDetails.data);
      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelName);
      setOptions(populatedOptions);
      return populatedOptions;
    }

    if (orgPartnersProp || orgPartnersTypeProp) {
      let populatedOptions = await partnerService.getPartnerByOrg({
        organizationId: orgPartnersProp,
        partnerTypeId: orgPartnersTypeProp,
      });
      populatedOptions = removeInactive(populatedOptions);
      // allow for custom selectors
      setOptionValue((previousState) => previousState || optionValueId);
      setOptionLabel((previousState) => previousState || optionLabelName);
      setOptions(populatedOptions);
      return populatedOptions;
    }

    // check if mode was specified
    // | yeno | timezone
    if (mode) {
      switch (mode) {
        // a simple yes / no mode type
        case 'yeno': {
          populatedOptions = [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ] as Options[];
          setOptions(populatedOptions);
          setOptionValue(optionValueGeneric);
          setOptionLabel(optionLabelGeneric);
          return populatedOptions;
        }

        // a simple yes / no / na mode type
        case 'yenona': {
          populatedOptions = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
            { label: 'N/A', value: 2 },
          ] as Options[];
          setOptions(populatedOptions);
          setOptionValue(optionValueGeneric);
          setOptionLabel(optionLabelGeneric);
          return populatedOptions;
        }

        // a simple yes / no / unknown mode type
        case 'yenouk': {
          populatedOptions = [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 },
            { label: 'Unknown', value: 2 },
          ] as Options[];
          setOptions(populatedOptions);
          setOptionValue(optionValueGeneric);
          setOptionLabel(optionLabelGeneric);
          return populatedOptions;
        }

        // a mode to show timezones [deprecated]
        // !! deprecated, please use the timezone built into FormDateTime
        case 'timezone': {
          const timezones = [
            'US/Eastern',
            'US/Pacific',
            'US/Mountain',
            'US/Central',
          ];

          // start empty array of timezones
          const offsetTmz: Options[] = [];

          // check each timezone
          timezones.forEach((time) => {
            const timeZoneOption = {
              label: `${moment(dateValue).tz(time).zoneAbbr()} (${
                moment(dateValue).tz(time).utcOffset() / 60
              })`,
              value: time,
            } as Options;

            // push it into the array of timezones
            offsetTmz.push(timeZoneOption);
          });

          // set the options to that array of timezones
          populatedOptions = offsetTmz;
          setOptionValue(optionValueGeneric);
          setOptionLabel(optionLabelGeneric);
          setOptions(populatedOptions);
          return populatedOptions;
        }

        case 'role': {
          let populatedOptions = await roleService.getAll();
          populatedOptions = removeInactive(populatedOptions);
          setFixedOptions([]);
          // allow for custom selectors
          setOptionValue((previousState) => previousState || optionValueId);
          setOptionLabel((previousState) => previousState || optionLabelName);
          setOptions(populatedOptions);
          return populatedOptions;
        }

        case 'sso-role': {
          let populatedOptions = await roleService.getAll();
          populatedOptions = removeInactive(populatedOptions);
          const ssoRoles = populatedOptions.filter(
            (roleOption: { sso: boolean }) => roleOption.sso === true,
          );
          setFixedOptions(ssoRoles);

          // allow for custom selectors
          setOptionValue((previousState) => previousState || optionValueId);
          setOptionLabel((previousState) => previousState || optionLabelName);
          setOptions(populatedOptions);
          return populatedOptions;
        }

        case 'permission': {
          let populatedOptions = await permissionService.getAll();
          populatedOptions = removeInactive(populatedOptions);
          // allow for custom selectors
          setOptionValue((previousState) => previousState || optionValueId);
          setOptionLabel((previousState) => previousState || optionLabelName);
          setOptions(populatedOptions);
          return populatedOptions;
        }

        case 'group': {
          let populatedOptions = await groupService.getAll();
          populatedOptions = removeInactive(populatedOptions);
          // allow for custom selectors
          setOptionValue((previousState) => previousState || optionValueId);
          setOptionLabel((previousState) => previousState || optionLabelName);
          setOptions(populatedOptions);
          return populatedOptions;
        }

        case 'user': {
          let populatedOptions = await userService.getAllUsers();
          populatedOptions = removeInactive(populatedOptions);
          // allow for custom selectors
          setOptionValue((previousState) => previousState || optionValueId);
          setOptionLabel(
            (previousState) => previousState || optionLabelFullName,
          );
          setOptions(populatedOptions);
          return populatedOptions;
        }

        // if unknown mode type was specified throw an error
        default: {
          throw new Error(
            `Mode was specified as ${mode} but is an unkown mode type in select.`,
          );
        }
      }
    }

    // check to see if an order has been specified
    // requires + or - at the start of ASC or DESC
    if (orderProp) {
      // get the operator from the first character of the string
      const orderOperation = orderProp.charAt(0);
      // remove the operator and get the rest of the string
      const orderSelector = orderProp.substring(1) as keyof Options;

      // sort based on the properties sent over
      populatedOptions = populatedOptions.sort((a, b) => {
        // sort in DESC order
        if (orderOperation === '-') {
          if (b[orderSelector] > a[orderSelector]) return 1;
          if (a[orderSelector] > b[orderSelector]) return -1;

          return 0;
        }

        // sort in ASC order
        if (orderOperation === '+') {
          if (a[orderSelector] > b[orderSelector]) return 1;
          if (b[orderSelector] > a[orderSelector]) return -1;

          return 0;
        }

        // if no order operation was given, then throw error
        throw new Error(
          'Form Select - No order operator is specified, please include a "+" or "-" before the selector',
        );
      });
    }

    // if no special props sent just use regular options
    populatedOptions = removeInactive([...optionsProp]);
    setOptions(removeInactive([...optionsProp]));
    return populatedOptions;
  }, [
    optionsProp,
    lookup,
    dateValue,
    mode,
    lookupWhere,
    rolesProp,
    orderProp,
    filterProp,
    organizationsProp,
    orgPartnersProp,
    orgPartnersTypeProp,
    outcomesProp,
    outcomeDetailsProp,
    initialValueField,
    optionValue,
    disabledOptionValues,
    outcomes.isFetching,
    outcomesDetails.isFetching,
    organizations.isFetching,
    usersByRole.isFetching,
    lookupOptions.isFetching,
    partnerOptions.isFetching,
  ]);

  // a function to get the value and check if there is
  // a default value to look for in the array of options
  const getSelectedValue = useCallback(() => {
    // set inital temporary value
    let tempValue: typeof selectedValue = null;
    // check to see if any value has been specified
    if (valueProp === null || valueProp === undefined || valueProp === '') {
      // if they can select multiple values, it has to start with an empty array
      if (multipleProp) {
        // tempValue = [];
        // @ts-ignore
        setSelectedValue([]);
        return [];
      }
      // tempValue = null;

      setSelectedValue(null);
      return null;
    }

    // throw warning if they supplied a value but did
    // not supply any options
    if (!options || !options.length) {
      // FIXME: was not able to throw error because
      // to many selects were trying to show before
      // options were loaded in, fix the selects
      // eslint-disable-next-line no-console
      // console.warn(`Supplied default value ${valueProp} without any options for select.`);
    }

    // if there is a value and it's secured hide the value
    if (securedProp) {
      setisFocused(true);
    }

    // check to see if an option value has been provided
    if (optionValue) {
      // find the object in array by using the value attribute supplied
      // and set value to that object
      if (multipleProp) {
        tempValue = options.filter((v) => {
          // @ts-ignore
          return valueProp.filter((a) => v[optionValue] === a).length;
        }) as typeof valueProp;
      } else {
        tempValue = options.find(
          // @ts-ignore
          (optionItem) => optionItem[optionValue] === valueProp,
        ) as typeof valueProp;
      }
      setSelectedValue(tempValue);
      mapValues({ value: optionValue, raw: tempValue });
      return tempValue;
    }

    // if there is no option value but still a value then
    // set the value to the default value
    tempValue = valueProp;
    mapValues({ value: optionValue, raw: optionValue });
    setSelectedValue(tempValue);
    return tempValue;
    // disabling eslint here because mapValues will cause an infinite loop if included here.
    // eslint-disable-next-line
  }, [options, optionValue, valueProp, securedProp]);

  // lifecycle method for initalizing the component and loading in props
  useEffect(() => {
    setIsLoading(true);
    // load in the options
    getPopulatedOptions().then(() => {
      setIsLoading(false);
    });
    return () => {
      setIsLoading(true);
    };
  }, [getPopulatedOptions]);

  // lifecycle method for setting the value to correct value
  useEffect(() => {
    getSelectedValue();
  }, [getSelectedValue]);

  // lifecycle method for setting correct styling based on options
  useEffect(() => {
    setStyling();
  }, [setStyling]);

  // lifecycle method for setting correct styling based on options
  useEffect(() => {
    checkIfDisabled();
  }, [checkIfDisabled]);

  // used to toggle mobile tooltip, auto closes in 5 sec
  const tipTitle = showOptionLabel(selectedValue);
  const toggleTip = () => {
    if (tipTitle) {
      setToolTipOpen(!toolTipOpen);
      setTimeout(() => {
        setToolTipOpen(false);
      }, 3000);
    }
  };
  // do not render component if loading instead render a loading symbol
  if (isLoading && (!noFlash ? true : !options?.length)) {
    // TODO: figure out which loading symbol to display here
    if (newLoader) {
      return (
        <Autocomplete
          {...props}
          sx={{
            '.MuiAutocomplete-inputRoot': {
              color:
                isInitialValue || noColorChange || isViewMode
                  ? 'inherit'
                  : '#af1685',
            },
            cursor:
              !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
            pointerEvents:
              !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
          }}
          loading={isLoading}
          options={options}
          disabled
          getOptionLabel={(option) => showOptionLabel(option)}
          isOptionEqualToValue={(option, state) =>
            checkOptionSelected(option, state)
          }
          getOptionDisabled={(option) =>
            // @ts-ignore
            ((lookup || organizationsProp) && !option.active) ||
            (fixedOptions && fixedOptions.indexOf(option) !== -1) ||
            // @ts-ignore
            !!disabledOptionValues?.includes(option[optionValue])
          }
          onChange={(e, value, r) => {
            handleChange(value);
            onChange(e, value, r);
          }}
          style={{ width, maxWidth, ...customStyle }}
          ChipProps={{
            size: 'small',
          }}
          groupBy={groupBy}
          // @ts-ignore
          value={selectedValue || null}
          autoHighlight
          // name={nameProp}
          multiple={multipleProp}
          onFocus={() => {
            setisFocused(true);
          }}
          onBlur={() => {
            setisFocused(false);
          }}
          readOnly={readOnlyProp || isViewMode}
          disableClearable={
            (readOnlyProp as DisableClearable) ||
            (removeIcons as DisableClearable)
          }
          freeSolo={(readOnlyProp as FreeSolo) || (removeIcons as FreeSolo)}
          renderTags={(tagValue, getTagProps) =>
            tagValue?.length &&
            tagValue.map((option, index) => {
              return (
                <Chip
                  label={showOptionLabel(option)}
                  {...getTagProps({ index })}
                  disabled={
                    (fixedOptions && fixedOptions.indexOf(option) !== -1) ||
                    // @ts-ignore
                    option.fixed
                  }
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiInput-root:before': {
                  borderBottom: readOnlyProp ? 'none' : 'auto',
                },
                '& .MuiInput-root:after': {
                  borderBottom: readOnlyProp ? 'none' : 'auto',
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  '.MuiOutlinedInput-notchedOutline': {
                    border:
                      !readOnlyProp && !isViewMode && requiredProp
                        ? '3px solid'
                        : tableProp
                        ? 0
                        : 'auto',
                  },
                  paddingTop: zeroPadding ? 0 : 'auto',
                  paddingBottom: zeroPadding ? 0 : 'auto',
                  padding: zeroPadding ? '0 !important' : 'auto',
                  height: zeroPadding ? 'undefined' : 'auto',
                  paddingLeft: zeroPadding ? 0 : 'auto',
                },
                style: inputStyling,
                disabled,
                tabIndex:
                  isViewMode || readOnlyProp || disabled ? -1 : undefined,
                classes: customClasses,
                readOnly: readOnlyProp || isViewMode,
                inputProps: {
                  ...params.inputProps,
                  tabIndex:
                    isViewMode || readOnlyProp || disabled ? -1 : undefined,
                  disabled,
                  autoComplete: 'new-password',
                },
              }}
              placeholder={placeholderProp}
              error={!!errorProp}
              helperText={errorProp || helperTextProp}
              label={label}
              size={sizeProp}
              variant={readOnlyProp ? 'standard' : variantProp}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
              required={requiredProp}
              autoComplete="new-password"
            />
          )}
        />
      );
    }
    return <div style={{ width }} />;
  }

  // mobile view render with tooltip
  if (mobileView) {
    return (
      <Tooltip
        title={tipTitle || ' '}
        disableFocusListener
        placement="top-start"
        open={toolTipOpen}
        sx={{
          '.MuiTooltip-tooltip': {
            width: 'fit-content',
            fontSize: '1.2rem',
          },
        }}
      >
        <div
          style={{ width: width || '100%' }}
          onClick={() => (isViewMode ? toggleTip : null)}
        >
          <Autocomplete
            {...props}
            sx={{
              '.MuiAutocomplete-inputRoot': {
                color:
                  isInitialValue || noColorChange || isViewMode
                    ? 'inherit'
                    : '#af1685',
              },
              cursor:
                !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
              pointerEvents:
                !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
            }}
            loading={isLoading}
            options={options}
            getOptionLabel={(option) => showOptionLabel(option)}
            isOptionEqualToValue={(option, state) =>
              checkOptionSelected(option, state)
            }
            getOptionDisabled={(option) =>
              // @ts-ignore
              ((lookup || organizationsProp) && !option.active) ||
              (fixedOptions && fixedOptions.indexOf(option) !== -1) ||
              // @ts-ignore
              !!disabledOptionValues?.includes(option[optionValue])
            }
            onChange={(e, value, r) => {
              handleChange(value);
              onChange(e, value, r);
            }}
            style={{ width, maxWidth, ...customStyle }}
            ChipProps={{
              size: 'small',
            }}
            groupBy={groupBy}
            // @ts-ignore
            value={selectedValue || null}
            autoHighlight
            // name={nameProp}
            multiple={multipleProp}
            onFocus={() => {
              setisFocused(true);
            }}
            onBlur={() => {
              setisFocused(false);
            }}
            readOnly={readOnlyProp || isViewMode}
            disableClearable={
              (readOnlyProp as DisableClearable) ||
              (removeIcons as DisableClearable)
            }
            freeSolo={(readOnlyProp as FreeSolo) || (removeIcons as FreeSolo)}
            renderTags={(tagValue, getTagProps) =>
              tagValue?.length &&
              tagValue.map((option, index) => {
                return (
                  <Chip
                    {...getTagProps({ index })}
                    label={showOptionLabel(option)}
                    disabled={
                      (fixedOptions && fixedOptions.indexOf(option) !== -1) ||
                      // @ts-ignore
                      option.fixed
                    }
                  />
                );
              })
            }
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& .MuiInput-root:before': {
                    borderBottom: readOnlyProp ? 'none' : 'auto',
                  },
                  '& .MuiInput-root:after': {
                    borderBottom: readOnlyProp ? 'none' : 'auto',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border:
                        !readOnlyProp && !isViewMode && requiredProp
                          ? '3px solid'
                          : tableProp
                          ? 0
                          : 'auto',
                    },
                    paddingTop: zeroPadding ? 0 : 'auto',
                    paddingBottom: zeroPadding ? 0 : 'auto',
                    padding: zeroPadding ? '0 !important' : 'auto',
                    height: zeroPadding ? 'undefined' : 'auto',
                    paddingLeft: zeroPadding ? 0 : 'auto',
                  },
                  style: inputStyling,
                  disabled,
                  tabIndex:
                    isViewMode || readOnlyProp || disabled ? -1 : undefined,
                  classes: customClasses,
                  readOnly: readOnlyProp || isViewMode,
                  inputProps: {
                    ...params.inputProps,
                    tabIndex:
                      isViewMode || readOnlyProp || disabled ? -1 : undefined,
                    disabled,
                    autoComplete: 'new-password',
                  },
                }}
                placeholder={placeholderProp}
                error={!!errorProp}
                helperText={errorProp || helperTextProp}
                label={label}
                size={sizeProp}
                variant={readOnlyProp ? 'standard' : variantProp}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
                required={requiredProp}
                autoComplete="new-password"
              />
            )}
          />
        </div>
      </Tooltip>
    );
  }

  // main render, the component is no longer loading
  return (
    <Autocomplete
      {...props}
      sx={{
        '.MuiAutocomplete-inputRoot': {
          color:
            isInitialValue || noColorChange || isViewMode
              ? 'inherit'
              : '#af1685',
        },
        cursor: !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
        pointerEvents:
          !writeOnly && (isViewMode || readOnlyProp) ? 'none' : 'auto',
      }}
      loading={isLoading}
      options={options}
      getOptionLabel={(option) => showOptionLabel(option)}
      isOptionEqualToValue={(option, state) =>
        checkOptionSelected(option, state)
      }
      getOptionDisabled={(option) =>
        // @ts-ignore
        ((lookup || organizationsProp) && !option.active) ||
        (fixedOptions && fixedOptions.indexOf(option) !== -1) ||
        // @ts-ignore
        !!disabledOptionValues?.includes(option[optionValue])
      }
      onChange={(e, value, r) => {
        handleChange(value);
        onChange(e, value, r);
      }}
      style={{ width, maxWidth, ...customStyle }}
      ChipProps={{
        size: 'small',
      }}
      groupBy={groupBy}
      // @ts-ignore
      value={selectedValue || null}
      autoHighlight
      // name={nameProp}
      multiple={multipleProp}
      onFocus={() => {
        setisFocused(true);
      }}
      onBlur={() => {
        setisFocused(false);
      }}
      readOnly={readOnlyProp || isViewMode}
      disableClearable={
        (readOnlyProp as DisableClearable) || (removeIcons as DisableClearable)
      }
      freeSolo={(readOnlyProp as FreeSolo) || (removeIcons as FreeSolo)}
      renderTags={(tagValue, getTagProps) =>
        tagValue?.length &&
        tagValue.map((option, index) => {
          return (
            <Chip
              label={showOptionLabel(option)}
              {...getTagProps({ index })}
              disabled={
                (fixedOptions && fixedOptions.indexOf(option) !== -1) ||
                // @ts-ignore
                option.fixed
              }
            />
          );
        })
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiInput-root:before': {
              borderBottom: readOnlyProp ? 'none' : 'auto',
            },
            '& .MuiInput-root:after': {
              borderBottom: readOnlyProp ? 'none' : 'auto',
            },
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              '.MuiOutlinedInput-notchedOutline': {
                border:
                  !readOnlyProp && !isViewMode && requiredProp
                    ? '3px solid'
                    : tableProp
                    ? 0
                    : 'auto',
              },
              paddingTop: zeroPadding ? 0 : 'auto',
              paddingBottom: zeroPadding ? 0 : 'auto',
              padding: zeroPadding ? '0 !important' : 'auto',
              height: zeroPadding ? 'undefined' : 'auto',
              paddingLeft: zeroPadding ? 0 : 'auto',
            },
            style: inputStyling,
            disabled,
            tabIndex: isViewMode || readOnlyProp || disabled ? -1 : undefined,
            classes: customClasses,
            readOnly: readOnlyProp || isViewMode,
            inputProps: {
              ...params.inputProps,
              tabIndex: isViewMode || readOnlyProp || disabled ? -1 : undefined,
              disabled,
              autoComplete: 'new-password',
            },
          }}
          placeholder={placeholderProp}
          error={!!errorProp}
          helperText={errorProp || helperTextProp}
          label={label}
          size={sizeProp}
          variant={readOnlyProp ? 'standard' : variantProp}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          required={requiredProp}
          autoComplete="new-password"
        />
      )}
    />
  );
}

export default memo(FormSelect);
