import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setUserData } from 'src/actions/accountActions';

import { setConfigData } from 'src/actions/configActions';
import authService from 'src/services/authService';
import * as Sentry from '@sentry/react';

const refreshWorker = () => {
  let refreshInterval;

  onmessage = (e) => {
    const { command } = e.data;
    switch (command) {
      case 'start': {
        refreshInterval = setInterval(() => {
          postMessage({
            command: 'refresh',
          });
        }, 1000 * 60 * 10);
        break;
      }
      case 'stop': {
        clearInterval(refreshInterval);
        break;
      }
      default:
        break;
    }
  };
};
function RefreshController() {
  function loadWebWorker(worker) {
    const code = worker.toString();
    const blob = new Blob([`(${code})()`]);
    return new Worker(URL.createObjectURL(blob));
  }
  const refreshWebWorker = loadWebWorker(refreshWorker);

  const dispatch = useDispatch();

  const refreshInformation = () => {
    return authService
      .refresh()
      .then((data) => {
        if (data?.config) {
          if (data?.user) {
            dispatch(setUserData(data.user));
            const sentryUser = {
              id: data.user.id,
              email: data.user.email,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
            };
            Sentry.setUser(sentryUser);
          } else {
            dispatch(setUserData(null));
            Sentry.setUser(null);
          }
          dispatch(setConfigData(data.config));
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  refreshWebWorker.onmessage = (e) => {
    const { command } = e.data;
    switch (command) {
      case 'refresh': {
        refreshInformation();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    refreshWebWorker.postMessage({ command: 'start' });
    return () => {
      refreshWebWorker.postMessage({ command: 'stop' });
      refreshWebWorker.terminate();
    };
  }, []);

  return null;
}

export default RefreshController;
