import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import ErrorIcon from '@mui/icons-material/Error';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone';
import { CaseBannerType } from 'types';
import AlertNoteDialog from './AlertNoteDialog';

const useStyles = makeStyles(() => ({
  toolbar: {
    minHeight: '25px',
  },
  listTypography: {
    color: 'white',
    fontSize: '12px',
  },
  donorName: {
    fontSize: '18px',
    fontWeight: 900,
  },
  infoKey: {
    textTransform: 'uppercase',
    marginTop: '25px',
    fontSize: '15px',
    fontWeight: 500,
    color: '#e4e1e6',
  },
  infoValue: {
    fontSize: '17px',
    color: 'white',
  },
}));

const fixedPrecision = (value: number) => {
  return Math.round(value * 100) / 100;
};

type Props = {
  banner: CaseBannerType;
};

function CaseBanner({ banner }: Props) {
  const referral = banner;
  const {
    height = 0,
    heightUnit = '',
    weight = 0,
    weightUnit = '',
  } = referral.donor || {};

  const donorKgWeight = () => {
    if (weightUnit === 'lbs') return weight ? weight / 2.205 : 0;
    return weight || 0;
  };

  const donorM2Area = () => {
    if (heightUnit === 'in') {
      if (!height) return 0;
      return ((height * 2.54) / 100) ** 2;
    }
    if (!height) return 0;
    return (height / 100) ** 2;
  };

  let BMI = fixedPrecision(donorKgWeight() / donorM2Area());
  if (!Number.isFinite(BMI) || Number.isNaN(BMI)) {
    BMI = 0;
  }

  const [alertNoteDialog, setAlertNoteDialog] = useState(false);

  const formatDin = (din: string | null) => {
    if (!din) return '';
    return `${din.slice(0, 5)} ${din.slice(5, 7)} ${din.slice(7, 13)}`;
  };

  const bannerItems = referral?.id
    ? [
        [
          {
            label: 'donor id',
            value: referral?.donor?.entityDonorId,
            serviceAlert: false,
          },
        ],
        [
          {
            label: 'din',
            value: formatDin(referral?.donor.din),
            serviceAlert: false,
          },
        ],
        [
          {
            label: 'Tissue Partner ID',
            value: referral?.donorIdTissue || '--',
            serviceAlert: false,
          },
        ],
        [
          {
            label: 'dob',
            value: moment(referral?.donor?.birthDate).format('MM/DD/YYYY'),
            serviceAlert: false,
          },
          {
            label: 'age',
            value: referral?.donor?.age,
            serviceAlert: false,
          },
        ],
        [
          {
            label: 'referring org',
            value: referral?.organization?.name,
            serviceAlert: !!referral?.organization?.serviceAlerts?.length,
          },
        ],
        [
          {
            label: 'MRN',
            value: referral?.donor?.mrn || '--',
            serviceAlert: false,
          },
        ],
        [
          {
            label: 'height',
            value: `${referral?.donor?.height || '--'} ${
              referral?.donor?.heightUnit
            }`,
            serviceAlert: false,
          },
          {
            label: 'weight',
            value: `${referral?.donor?.weight || '--'} ${
              referral?.donor?.weightUnit
            }`,
            serviceAlert: false,
          },
        ],
        [
          {
            label: 'bmi',
            value: BMI > 0 ? BMI : '--',
            serviceAlert: false,
          },
          {
            label: 'Gender',
            value: referral?.donor.gender || '--',
            serviceAlert: false,
          },
        ],
        [
          {
            size: '15px',
            label: 'date/time of death ',
            serviceAlert: false,
            value: !referral?.donor?.deathDate
              ? ''
              : moment(referral.donor.deathDate)
                  .tz(referral?.donor?.deathDateTimezone || '')
                  .format('MM/DD/YYYY HH:mm z'),
          },
        ],
        [
          {
            size: '15px',
            label: 'date/time of admit ',
            serviceAlert: false,
            value: !(
              referral?.donor?.admissionDate &&
              referral?.donor?.admissionDateTimezone
            )
              ? ''
              : moment(referral.donor.admissionDate)
                  .tz(referral.donor.admissionDateTimezone)
                  .format('MM/DD/YYYY HH:mm z'),
          },
        ],
      ]
    : [];

  const classes = useStyles();
  if (referral.id == null) {
    return <p>Loading...</p>;
  }

  return (
    <Box marginBottom="5px">
      <div className={classes.toolbar} />
      {referral.locked && (
        <Box
          marginBottom="5px"
          justifyContent="center"
          display="flex"
          alignItems="center"
          gap="5px"
        >
          <LockIcon style={{ color: 'white' }} />{' '}
          <Typography
            variant="overline"
            className={classes.listTypography}
          >
            LOCKED
          </Typography>
        </Box>
      )}
      {referral && referral.jurisdictionAssumed && (
        <StarIcon style={{ color: 'yellow', marginRight: '10px' }} />
      )}
      <Tooltip
        placement="top"
        title={
          referral?.donor?.registered === true ? 'Registered' : 'Not registered'
        }
      >
        <HowToRegIcon
          style={{
            color: referral?.donor?.registered === true ? 'yellow' : 'red',
            marginRight: '10px',
            display: referral?.donor?.registered == null ? 'none' : '',
          }}
        />
      </Tooltip>
      {referral && referral.evaluationReceived === true && (
        <RouterLink to="approvals/approval/">
          <VisibilityIcon style={{ color: 'white' }} />
        </RouterLink>
      )}
      <Box>
        <Typography className={classes.donorName}>
          {referral.donor.lastName}, {referral.donor.firstName}
        </Typography>
      </Box>

      {bannerItems.map((bannerItem) => (
        <Box
          key={bannerItem[0].label}
          justifyContent="space-between"
          display="flex"
        >
          {bannerItem.map((item) => {
            return (
              <Box
                key={item.label}
                alignItems="center"
              >
                {item.serviceAlert ? (
                  <Box
                    marginTop="25px"
                    alignItems="center"
                    display="flex"
                  >
                    <IconButton
                      style={{
                        padding: 0,
                      }}
                      onClick={() => setAlertNoteDialog(true)}
                      size="large"
                    >
                      <ErrorIcon
                        style={{ color: 'yellow', fontSize: '35px' }}
                      />
                    </IconButton>
                    <AlertNoteDialog
                      open={alertNoteDialog}
                      onClose={() => setAlertNoteDialog(false)}
                      notes={referral.organization.serviceAlerts}
                    />
                  </Box>
                ) : null}
                <Box>
                  <Typography
                    variant="body1"
                    style={{
                      marginTop: item.serviceAlert ? '0px' : undefined,
                    }}
                    className={classes.infoKey}
                  >
                    {item.label}
                  </Typography>
                  <Typography className={classes.infoValue}>
                    {item.value}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
}

export default CaseBanner;
