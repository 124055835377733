/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from 'src/reducers';
import { ENABLE_REDUX_LOGGER } from 'src/config';
import { serverState, rtkQueryErrorLogger } from 'src/store/serverState';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const loggerMiddleware = createLogger();

export function createStore(preloadedState = {}) {
  const middleware = [
    thunkMiddleware,
    rtkQueryErrorLogger,
    serverState.middleware,
  ];

  if (ENABLE_REDUX_LOGGER) {
    middleware.push(loggerMiddleware);
  }

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(thunkMiddleware)
        .concat(rtkQueryErrorLogger)
        .concat(serverState.middleware),
  });

  return store;
}

const store = createStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
