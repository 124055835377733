import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';
import { stripNull } from 'src/utils/object';

const baseURL = '/public/cases';

class CaseService {
  updateCaseLock = async (caseId, payload) => {
    try {
      const request = await axios.put(`${baseURL}/${caseId}/lock`, payload);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getCaseLock = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/lock`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getCaseLockHx = async (caseId) => {
    try {
      const request = await axios.get(`${baseURL}/${caseId}/lockHx`);
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.msg);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  createDonationDiscussion = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/donationDiscussions`,
        payload,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getDonationDiscussion = async (caseId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/donationDiscussions`,
      );
      if (request.status === 200) {
        return request.data.record;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* PRELIMINARY HEMODILUTION */
  getPreliminaryHemodilutionInformation = async (caseId) => {
    try {
      const request = await axios
        .get(`${baseURL}/${caseId}/preliminaryHemodilutions`)
        .catch((err) => {
          throw new Error(err);
        });

      return request.data.record;

      // throw new Error(request.data);
    } catch (error) {
      throw new Error(error);
    }
  };

  getPrelimHemoBlankFormData = async (caseId) => {
    try {
      const request = await axios.get(
        `${baseURL}/${caseId}/preliminaryHemodilutions/new`,
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updatePreliminaryHemodilutionInformation = async (caseId, payload) => {
    try {
      const request = await axios.post(
        `${baseURL}/${caseId}/preliminaryHemodilutions`,
        stripNull({
          ...payload,
          serverState: null,
        }),
      );
      if (request.status === 200) {
        return request.data;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /* CONTACTS/ KINS */

  getContactsKins = async (caseId) => {
    try {
      const request = await axios.get(`/public/caseContact/${caseId}/kins`);
      if (request.status === 200) {
        return request.data.kins;
      }
      throw new Error(request.data.message);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
const caseService = new CaseService();

export default caseService;
